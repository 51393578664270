const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app></linda-app>',
  },
  // header
  {
    name: 'app.root.l1',
    abstract: true,
    template: '<linda-view-layer-one></linda-view-layer-one>',
  },
  {
    name: 'app.root.l1.l8.l2',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },
  {
    name: 'app.root.l1.l8.l2.l3',
    uabstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },
  {
    name: 'app.root.l1.l8.l2.l4',
    uabstract: true,
    template: '<linda-view-layer-four></linda-view-layer-four>',
  },
  /// Цей леєр додає футер до наших сторінок
  {
    name: 'app.root.l1.l8',
    uabstract: true,
    template: '<linda-view-layer-eight></linda-view-layer-eight>',
  },
  {
    name: 'app.root.l1.l8.home',
    url: '/',
    template: '<linda-homepage scroll-to-top=""></linda-homepage>',
  },
  {
    name: 'app.root.summer',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top="" ></summer-promo-wrapper>',
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer',
    private: true,
    template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
  },
  {
    name: 'app.root.l1.l8.l2.games',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.l1.l8.l2.l4.games',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.l1.l8.l2.l3.last-played',
    url: '/games/continue-playing',
    private: true,
    template: '<batman-recently-played-list3 scroll-to-top><batman-recently-played-list3/>',
    params: {
      name: 'last-played',
      collection: 'games',
    },
  },
  {
    name: 'app.root.l1.l8.l2.l3.favourites',
    url: '/favourites',
    template: '<batman-game-box-favourites1 scroll-to-top=""></batman-game-box-favourites1>',
    params: {
      name: 'favourites-games',
      collection: 'games',
    },
  },
  {
    name: 'app.root.l1.l8.l2.l4.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall2 scroll-to-top=""/>',
    params: {
      providers: 'provider',
    },
  },
  {
    name: 'app.root.l1.game',
    url: '/pre-game/:name',
    template: '<linda-game-page />',
  },

  // <MACHINES> ...
  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },
  //- </MACHINES>
  {
    name: 'app.root.real',
    url: '/play/:name/real',
    template: '<linda-game-page-mobile />',
    gamePage: false,
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.demo',
    url: '/play/:name/demo',
    template: '<linda-game-page-mobile />',
    gamePage: false,
    params: {
      mode: 'demo',
    },
  },
  {
    name: 'app.root.l1.bonus',
    abstract: true,
    template: '<linda-profile-bonus-list />',
    private: true,
  },
  {
    name: 'app.root.l1.bonus.active',
    url: '/account/bonuses',
    template: '<linda-bonus-list-mob-rabona scroll-to-top=""/>',
    params: {
      bonusType: 'active',
    },
  },
  {
    name: 'app.root.l1.bonus.casino',
    url: '/account/bonuses/casino',
    template: '<linda-bonus-list-mob-rabona scroll-to-top=""/>',
    params: {
      bonusType: 'casino',
    },
  },
  {
    name: 'app.root.l1.bonus.sport',
    url: '/account/bonuses/sport',
    template: '<linda-bonus-list-mob-rabona scroll-to-top=""/>',
    params: {
      bonusType: 'sport',
    },
  },
  {
    name: 'app.root.l1.profile',
    url: '/account',
    template: '<linda-profile-settings scroll-to-top=""/>',
    private: true,
  },
  {
    name: 'app.root.l1.history',
    url: '/account/balance-history',
    template: '<linda-cashbox-balance-information scroll-to-top=""/>',
    private: true,
  },
  {
    name: 'app.root.l1.achievement',
    url: '/account/weekly-achievement',
    template: '<linda-achievements-page scroll-to-top=""></linda-achievements-page>',
    params: {
      tab: 'sport',
    },
  },
  {
    name: 'app.root.l1.verification',
    url: '/account/verification',
    template: '<dvs-page />',
    private: true,
  },
  {
    name: 'app.root.l1.mybets',
    url: '/account/mybets',
    template: '<batman-ui-wrapper1 scroll-to-top=""/>',
    params: {
      page: 'mybets',
      isHashMode: false,
    },
  },
  {
    name: 'app.root.l1.l8.404',
    url: '/404',
    template: '<not-found></not-found>',
  },
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback />',
  },
  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback />',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback />',
    params: {
      status: 'fail',
    },
  },

  // For redirecting to homepage after clicking home button in game iframe
  {
    name: 'app.root.callback',
    url: '/callback/:name',
    template: '<linda-callback-page/>',
  },

  /* =============================================== */
  /* ================= INFO PAGES ================= */
  /* =============================================== */
  // not ready

  {
    name: 'app.root.l1.l5',
    abstract: true,
    template: '<linda-view-layer-five></linda-view-layer-five>',
  },

  {
    name: 'app.root.l1.l5.contact-us',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
      footerGroup: 'general',
    },
  },

  {
    name: 'app.root.l1.l5.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
      footerGroup: 'general',
    },
  },

  {
    name: 'app.root.l1.l5.about',
    url: '/about',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
      footerGroup: 'general',
    },
  },

  {
    name: 'app.root.l1.l5.terms',
    url: '/rules',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
      footerGroup: 'privacy',
    },
  },
  {
    name: 'app.root.l1.l5.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
      footerGroup: 'privacy',
    },
  },
  {
    name: 'app.root.l1.l5.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
      footerGroup: 'privacy',
    },
  },
  {
    name: 'app.root.l1.l5.cookies-policy',
    url: '/cookies-policy',
    template: '<linda-info-page scroll-to-top=""></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
      footerGroup: 'privacy',
    },
  },
  {
    name: 'app.root.l1.l5.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top=""></linda-sitemap>',
    params: {
      footerGroup: 'general',
    },
  },

  /* ===================================================*/

  {
    name: 'app.root.l1.l8.promo',
    abstract: true,
    template: '<linda-view-layer-six />',
  },
  {
    name: 'app.root.l1.l8.promo.list',
    url: '/promotions',
    template: '<linda-promos scroll-to-top/>',
    params: {
      tab: 'sport',
    },
  },
  {
    name: 'app.root.l1.l8.promo.item',
    url: '/promotions/:name',
    template: '<linda-promo-page scroll-to-top="" promo="$resolve.promo"/>',
    resolve: {
      promo: ['promoItemResolver', function (promoItemResolver) {
        return promoItemResolver.resolve();
      }],
    }
  },
  {
    name: 'app.root.l1.l8.sport',
    abstract: true,
    template: '<linda-sport-page />',
  },
  {
    name: 'app.root.l1.l8.sport.prelive',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids', //if sport: '/?sportids&catids&champids&tabs&eventid'
    template: '',
    params: {
      page: 'prelive',
      isHashMode: false,
      footerGroup: 'sport',
    },
  },
  {
    name: 'app.root.l1.l8.sport.live',
    url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids', //'/live?sportids&catids&champids&tabs&eventid'
    template: '',
    params: {
      page: 'live',
      isHashMode: false,
      footerGroup: 'sport',
    },
  },
  {
    name: 'app.root.l1.l8.sport.virtual',
    url: '/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm',
      isHashMode: false,
      footerGroup: 'sport',
    },
  },
  {
    name: 'app.root.l1.l8.horse_racing',
    abstract: true,
    template: '<linda-view-layer-seven />',
  },
  {
    name: 'app.root.l1.l8.horse_racing.horse_racing',
    url: '/horse-racing',
    params: {
      footerGroup: 'sport',
      category: 'horseRacing',
    },
  },
  {
    name: 'app.root.l1.l8.horse_racing.greyhound',
    url: '/greyhound',
    params: {
      footerGroup: 'sport',
      category: 'greyhound',
    },
  },
  {
    name: 'app.root.l1.l8.horse_racing.harness',
    url: '/harness',
    params: {
      footerGroup: 'sport',
      category: 'harness',
    },
  },
  {
    name: 'app.root.l1.l8.horse_racing.next-to-jump',
    url: '/next-to-jump',
    params: {
      footerGroup: 'sport',
      category: 'nextToJump',
    },
  },
  {
    name: 'app.root.l1.l8.tournaments',
    url: '/tournaments',
    template: '<batman-tournament-list1 players="true" competitors="5" scroll-to-top=""/>',
    params: {
      footerGroup: 'promotions',
    },
  },
  {
    name: 'app.root.l1.l8.tournament',
    url: '/tournaments/:name',
    template: '<linda-tournament-page scroll-to-top tournament="$resolve.tournament"/>',
    params: {
      hasLeagues: true,
    },
    resolve: {
      tournament: ['tournamentItemResolver', function (tournamentItemResolver) {
        return tournamentItemResolver.resolve();
      }],
    },
  },
  {
    name: 'app.root.l1.l8.season-tournament',
    url: '/australian-open',
    template: '<batman-view-layer2 scroll-to-top />',
    params: {
      name: 'australian-open',
      champids: '3013',
      hasBanner: true,
    },
  },
  // {
  //   name: 'app.root.l1.l8.sporttournament',
  //   url: '/sport-tournament',
  //   template: '<batman-view-layer2 scroll-to-top />',
  //   params: {
  //     name: 'the-masters-rabona',
  //     hasLeagues: true,
  //   },
  // },
  // sport collection
  {
    name: 'app.root.l1.l8.collections',
    abstract: true,
    template: '<batman-collection-list1 />',
  },
  {
    name: 'app.root.l1.l8.collections.all',
    url: '/collections',
    template: '<batman-sport-team-list1 scroll-to-top=""/>',
    params: {
      footerGroup: 'promotions',
    },
  },
  {
    name: 'app.root.l1.l8.collections.my',
    url: '/collections-my',
    template: '<batman-sport-team-list2 scroll-to-top=""/>',
  },
  {
    name: 'app.root.l1.l8.collections.guide',
    url: '/collections-terms',
    template: `<linda-article-box name='"mobile-collection-guide"' scroll-to-top=""/>`,
  },
  {
    name: 'app.root.l1.l8.collection',
    url: '/collections/:name',
    template: '<linda-sport-team-page scroll-to-top=""/>',
    // template: '<batman-view-layer7 scroll-to-top=""/>',
    private: true,
  },

  /////////////////////////////////////
  // VIP
  /////////////////////////////////////
  {
    name: 'app.root.l1.l8.vip',
    url: '/vip',
    template: '<linda-vip scroll-to-top="" ></linda-vip>',
    params: {
      footerGroup: 'promotions',
    },
  },

  /////////////////////////////////////
  // CUP
  /////////////////////////////////////
  {
    name: 'app.root.l1.l8.cup',
    private: true,
    url: '/champions-cup',
    template: '<linda-cup-page scroll-to-top="" />',
    // template: '<batman-view-layer6 scroll-to-top="" />',
    params: {
      footerGroup: 'promotions',
    },
  },

  {
    name: 'app.root.l1.paynplay',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page />',
    params: {
      toolbarHide: true,
    },
  },

  /////////////////////////////////////
  // Seasons
  /////////////////////////////////////

  // {
  //   name: 'app.root.l1.l8.seasons',
  //   private: false,
  //   url: '/seasons/:name',
  //   template: `<linda-promo-wrapper scroll-to-top=""></linda-promo-wrapper>`,
  // },
  // {
  //   name: 'app.root.l1.l8.seasons',
  //   private: false,
  //   url: '/seasons/:name',
  //   template: '<easter-promo-mobile scroll-to-top to-top-on-state-change="{selector: \'.screen\'}" />',
  // },

  //////////////////////////
  // Missed data
  /////////////////////////
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    template: '<linda-missed-data-form/>',
  },
  /////////////////////////////////////
  // All payments page
  /////////////////////////////////////
  {
    name: 'app.root.l1.l8.payments-list',
    url: '/payments-list',
    template: '<batman-banking-page1 class="screen" scroll-to-top=""></batman-banking-page1>',
  },
  /*
   * PWA instruction page
   * **/
  {
    name: 'app.root.l1.l8.pwa-instruction',
    url: '/pwa-instruction',
    template: `<linda-info-page class="screen" scroll-to-top=""></linda-info-page>`,
    params: {
      pageName: 'pwa',
      name: 'pwa-instruction',
    },
  },

  {
    name: 'app.root.l1.l8.l2.l3.gamehall',
    url: '/:collection',
    template: '<linda-casino-section scroll-to-top=""></linda-casino-section>',
    params: {
      collection: 'games',
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  {
    name: 'app.root.l1.l8.l2.l3.games',
    url: '/:collection/:category',
    template: '<batman-game-hall1 scroll-to-top=""/>',
    params: {
      collection: 'games',
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  // {
  //   name: 'app.root.l1.l8.tyson_ambassador',
  //   url: '/tyson-ambassador',
  //   template: '<batman-view-layer5 scroll-to-top="" ></batman-view-layer5>',
  // },
  // For aliases updates test
  // {
  //   name: "app.root.l1.aliases-list-page",
  //   url: "/aliases-list",
  //   template: '<batman-view-layer8></batman-view-layer8>',
  // }
];

export { router };
